const techSliderdata = [
  {
    id: 1,
    images: "./Images/TechSlider/ui.avif",
    hdl: "Frontend technologies",
    description:
      "With the latest frontend technologies, we can help you create an exceptional user experience that is both visually stunning and highly functional. Whether you are building a website, web application, or mobile app, our team of frontend experts can help you craft a custom design that reflects your brand's personality and values.",
  },
  {
    id: 2,
    images: "./Images/TechSlider/mobile-app.avif",
    hdl: "Manual & Automation QA",
    description:
      "Ensure software quality with our manual & automation QA services. Comprehensive manual testing and streamlined automated testing to uncover issues. Tailored approach to fit your unique needs.",
  },

  {
    id: 3,
    images: "./Images/TechSlider/content-creator.avif",
    hdl: "Content Management",
    description:
      "Our expert content management support is designed to seamlessly integrate with any system involved in your digital environment. Whether you're using a content management system (CMS), customer relationship management (CRM) tool, or any other platform, our team can manage your content across all systems.",
  },

  {
    id: 4,
    images: "./Images/TechSlider/goal.avif",
    hdl: "Nearshoring Services",
    description:
      "Our nearshore team is located in a nearby time zone and speaks your language, making communication and collaboration seamless and hassle-free. With our nearshore services, you can enjoy all the benefits of outsourcing, such as cost savings and access to top talent, while still maintaining close control over your project and ensuring that your specific business needs are met.",
  },
];

export default techSliderdata;
