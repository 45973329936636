const servicesOfferdata = [

 {
   id: 1,
   title:'Web Application Development',
   images: require("../../../src/Assets/images/pair.webp"),
   description:
     "Our web development team creates dynamic and responsive web applications that offer seamless user experiences. From e-commerce platforms to content management systems, we build solutions that captivate and engage users.",
 },

 {
   id: 2,
   title:' Quality Assurance and Testing',
   images: require("../../../src/Assets/images/germany.png"),
   description:
     "Ensure the reliability and performance of your software with our rigorous quality assurance and testing processes. We conduct comprehensive testing to identify and eliminate any issues before deployment.",
 },
 {
   id: 3,
   title:'Maintenance and Support',
   images: require("../../../src/Assets/images/img1-first-slider.png"), 
   description:
     "Our commitment doesn't end with development. We provide ongoing maintenance and support services, ensuring that your software remains up-to-date, secure, and optimized for peak performance.",
 },

];

export default servicesOfferdata;