import React, { useState, useEffect } from "react";
import acordionData from "./AcordionData";

const Lifecycle = ({ activeItemIndex }) => {
  // const [isAnimating, setIsAnimating] = useState(Array(acordionData.length).fill(false));

  // useEffect(() => {
  //   if (activeItemIndex !== null) {
  //     setIsAnimating((prev) => {
  //       const updatedAnimationStates = [...prev];
  //       updatedAnimationStates[activeItemIndex] = true;
  //       return updatedAnimationStates;
  //     });

  //     setTimeout(() => {
  //       setIsAnimating((prev) => {
  //         const updatedAnimationStates = [...prev];
  //         updatedAnimationStates[activeItemIndex] = false;
  //         return updatedAnimationStates;
  //       }, 1000);
  //     });
  //   }
  // }, [activeItemIndex]);

  return (


       <div className="flex flex-wrap gap-9 justify-center animation-container">
      {acordionData.map((data, index) => {
        return (
          <div key={index}>
            <div className="lifecycle-container relative">
              <div className="bg-whyUsCardColor w-fit rounded-full">
                <img src={data.images} className="w-[120px] p-8" alt={data.title} />
              </div>
              <div className="bubble-overlay absolute bottom-[-14px] left-[-14px] right-0 bg-whyUsCardColorShadow rounded-full overflow-hidden w-[125%] h-[125%] z-[-2]"></div>
            </div>
            <p className="bubble-text text-center hdl-5 uppercase text-footerColor">
              {data.title}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Lifecycle;

