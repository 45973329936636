import servicesOfferdata from "./servicesOfferdata";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
const ServicesOffered = () => {
  return (
    <section className="container">
      {/* <h2 className="hdl-3 sm:hdl-2 sm:text-center text-left">What We Provide</h2> */}
      <div className="teaser-wrapper reveal sm:py-10 py-6 justify-center row">
        <div className="content flex flex-col justify-center col-5">
          <div className="services-provided">
            <p className="hdl-2  mb-0 uppercase">What We Provide​​</p>
            <p className="copy-text">
              DELAtech offers full services to clients for the whole cycle of
              designing, developing, operating the information system, software
              development, managing and improving performance effectiveness of
              IT teams.
            </p>
          </div>
        </div>
        <div className="card-img col-5 flex flex-col justify-center">
          <LazyLoadImage
            className=" w-full max-h-[450px] my-0 mx-auto rounded-2xl hidden sm:block"
            alt="benefits-img"
            src="./Images/Career/office.webp"
          ></LazyLoadImage>
        </div>
      </div>
      <div className="multi-teasers">
        {servicesOfferdata.map((data) => {
          return (
            <div
              className="teaser-wrapper reveal sm:py-10 py-6 justify-center row"
              key={data.id}
            >
              <div className="card-img col-6">
                <LazyLoadImage
                  className="w-full max-h-[450px] my-0 mx-auto rounded-t-2xl sm:rounded-2xl"
                  alt="benefits-img"
                  src={data.images}
                ></LazyLoadImage>
              </div>
              <div className="content flex flex-col justify-center col-4">
                <div className="cart sm:absolute bg-white shadow-shadow z-999 p-8 rounded-b-2xl sm:rounded-2xl">
                  <p className="hdl-4 text-center mb-0 uppercase">
                    {data.title}
                  </p>
                  <p className="copy-text text-center">{data.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default ServicesOffered;
