import Acordion from "./Acordion";
import Lifecycle from "./Lifecycle";
import React, { useState } from "react";

const DevelopementLifecycle = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to handle accordion item clicks
  const handleAccordionClick = (index) => {
    setActiveIndex(index);
  };


const animationBubbles = (index) => {
    const parent = document.querySelector(".animation-container");
    const childElements = parent.querySelectorAll(".lifecycle-container");
  
    childElements.forEach((element, i) => {
      if (i === index) {
        element.classList.add("active-bubble");
      } else {
        element.classList.remove("active-bubble");
      }
    });
  };
  
  
  return (
    <section className="container reveal">
      <div className="sm:py-10 py-6 lg:justify-center row">
        <div className=" justify-center col-10">
          <p className="hdl-2 uppercase typing-animation">Custom Software Development Lifecycle</p>
          <p className="copy-text justify-center ">
            We employ best practice processes and development methodologies as a
            foundation for rapid building of cutting-edge technology solutions in a
            structured and methodical way.
          </p>
        </div>
        <div className="cp-row justify-center">
          <div className="col-12 md:col-6">
            <Acordion setActiveIndex={handleAccordionClick} animationBubbles={animationBubbles} />
          </div>
          <div className=" col-12 md:col-6 lg:col-4 mt-10">
            <Lifecycle activeItemIndex={activeIndex} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevelopementLifecycle;