// import TechSlider from "../TechSlider/TechContent";
import React, { useEffect, useRef } from "react";
import WhyUs from "../WhyUs/WhyUs";
import StageServices from "../StageServices/StageServices";
import ServicesOffered from "../ServicesOffered/ServicesOffered";
import DevelopementLifecycle from "../DevelopementLifecycle/DevelopementLifecycle";
import { animations } from "../../Animations/animations";
const OurServices = () => {
window.addEventListener("scroll", animations);
  return (
    <div className="our-services">
      <StageServices />
      <WhyUs />
      <ServicesOffered />
      <DevelopementLifecycle />
    </div>
  );
};
export default OurServices;
