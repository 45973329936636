const toolsData =[
    {
        src: require("../../Assets/tools/azure.png"),
        alt: "azure",
    },
    {
        src: require("../../Assets/tools/visual-studio-code.png"),
        alt: "visual-studio-code",
    },
    {
        src: require("../../Assets/tools/sourcetree.png"),
        alt: "sourcetree",
    },
    {
        src: require("../../Assets/tools/git.png"),
        alt: "Git",
    },
    {
        src: require("../../Assets/tools/2017-visual-studio.png"),
        alt: "2017-visual-studio",
    },
    {
        src: require("../../Assets/tools/jira.png"),
        alt: "jira",
    },  
    {
        src: require("../../Assets/tools/browserstack.png"),
        alt: "2017-visual-studio",
    },
    {
        src: require("../../Assets/tools/testing-postman.png"),
        alt: "jira",
    }, 
   
     
    ];
    
    export default toolsData;