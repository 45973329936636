import React from "react";
import benefitsNewdata from "./BenefitsNewdata";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BenefitsNew() {
  return (
    <section className="container">
      <h2 className="hdl-3 sm:hdl-2 text-center">Perks And Benefits</h2>
      <div className="multi-teasers">
        {benefitsNewdata.map((data, index) => {
          return (
            <div
              className="teaser-wrapper reveal sm:py-10 py-6 justify-center row"
              key={index}
            >
              <div className="card-img col-6 large:col-5 flex flex-col justify-center">
                <LazyLoadImage
                  className="w-full rounded-[50%] max-w-[300px] max-h-[300px] my-0 mx-auto"
                  alt="benefits-img"
                  src={data.images}
                ></LazyLoadImage>
              </div>
              <div className="content flex flex-col justify-center col-6 large:col-5">
                <p className="hdl-4 text-center mb-0 uppercase">{data.title}</p>
                <p className="copy-text text-center">{data.description}</p>
              </div>
              <div></div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default BenefitsNew;
