import React from "react";
import "./griddebugger.scss";

function Grid() {
  const url = window.location.href,
    gridParam = url.match(/\?(.*)/g),
    body = document.body;
  if (!gridParam) {
    return;
  }
  if (gridParam[0] === "?showgrid") {
    body.classList.add("show-grid-debugger");
    return (
      <div className="grid-debugger">
        <div className="container">
          <div className="row">
            {Array.from({ length: 12 }, (_, i) => (
              <span className="col-1" key={i}></span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default Grid;
