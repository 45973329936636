import Modal from "../Modal/Modal";
import ApplyForm from "./ApplyForm";
import React, { useEffect } from "react";
import data from "./data";
import "./ApplyForm.scss";
const OpenPositionsApply = (props) => {
  const jobTitle = props.onGetData;
  return (
    <Modal variant="white" headline={jobTitle} onClose={props.onClose}>
      <div className="relative job-apply mx-5">
        {data.map((el) => {
          if (el.jobtitle === jobTitle) {
            return (
              <div className="w-full mt-5 max-sm:mb-10" key={el.id}>
                <h3 className="text-base sm:text-lg topline">
                  The job and technologies:
                </h3>
                <ul>
                  <li
                    className="copy-text list-disc"
                    dangerouslySetInnerHTML={{
                      __html: el.criteria,
                    }}
                  ></li>
                  <h3 className="text-base sm:text-lg topline">Your profile</h3>
                </ul>
                <ul>
                  <li
                    className="copy-text list-disc"
                    dangerouslySetInnerHTML={{
                      __html: el.qualifications,
                    }}
                  ></li>
                  <h3 className="text-base sm:text-lg topline">
                    Your benefits
                  </h3>
                </ul>

                <ul>
                  <li
                    className="copy-text list-disc"
                    dangerouslySetInnerHTML={{
                      __html: el.benefits,
                    }}
                  ></li>
                </ul>
                <a href="mailto:info@dela-tech.com">
                  <button className="my-5 bg-darkGreen rounded-3xl text-white font-bold active:bg-formButtonHover hover:bg-formButtonHover shadow-shadow text-center py-0.5 px-6 h-12 transition-background-color duration-300 ease-linear ">
                    Apply now
                  </button>
                </a>
              </div>
            );
          }
        })}

        {/* <div className="md:w-6/12 mt-5 md:ml-10 relative ">
              <ApplyForm onGetPosition={jobTitle} />
            </div> */}
      </div>
    </Modal>
  );
};
export default OpenPositionsApply;
