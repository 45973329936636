const ServicesStagedata =[
    {
        id:1,
        headliner:"SOFTWARE DEVELOPMENT SERVICES FOR YOUR INNOVATIVE IDEAS",
        description:"DELAtech is rapidly growing provider of dedicated remote development teams and IT nearshoring solutions for companies of all sizes.",
        img:"./Images/StageSlider/empty-top.jpg",
    },

];

export default ServicesStagedata;