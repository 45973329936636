const jobPositiondata = [
  {
    id: 1,
    jobtitle:'Senior Full Stack Developer',
    jobsmallDescription: "Team Leader",
  },
  {
      id: 2,
      jobtitle:'Senior Front End',
      jobsmallDescription: "Team Leader",
 
    },
    {
      id: 3,
      jobtitle:'Senior .NET Developer',
      jobsmallDescription: "Developement",
    },
    
];

export default jobPositiondata;
