import "../ContactUs/ContactUs.scss";
import Modal from "../Modal/Modal";
import { GrClose } from "react-icons/gr";
import FormData from "form-data";
import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
const JobApply = (props) => {
  const [firstname, setFirstname] = useState("");
  // const [isJobApplyOpened, setIsJobApplyOpened] = useState(false);

  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [fileContent, setFileContent] = useState("");
  const jobPosition = props.onGetData;
  const allowedFileTypes = ["application/pdf", "text/plain"];
  const formRef = useRef("");

  // const closeSelectedJob = () => {
  //   setIsJobApplyOpened(false);
  // };
  function SubmitButton() {
    if (firstname || email || fileContent) {
      return (
        <button type="submit" onClick={(sendEmail, props.onClose)}>
          Submit
        </button>
      );
    } else {
      return (
        <button type="submit" onClick={sendEmail}>
          Submit
        </button>
      );
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e.type, formRef.current);
    const formDataTest = formRef.current;

    var formData = new FormData();
    formData.append("name", firstname);
    formData.append("email", email);
    formData.append("jobposition", jobPosition);
    formData.append("attachment", fileContent);

    for (var key of formData.entries()) {
      console.log(key[0] + ":" + key[1]);
    }
    const fileInput = formRef.current.querySelector('input[type="file"]');

    // .sendForm(
    //   "service_1umdocq",
    //   "template_u2b9w58",
    //   formRef.current,
    //   "-EHKjnJAv3q0Fd3Ph"
    // )
    if (fileInput) {
      emailjs
        .send(
          "service_1umdocq",
          "template_u2b9w58",
          {
            name: firstname,
            email: email,
            openJob: jobPosition,
            attachment: fileContent,
          },
          "-EHKjnJAv3q0Fd3Ph"
        )
        .then(
          function (response) {
            // location.href='mapsAPI.html
            console.log("success", response);
          },
          function (error) {
            console.log("FAILED", error);
          }
        );
      // .then(
      //   (result) => {
      //     console.log(result.text);
      //   },
      //   (error) => {
      //     console.log(error.text);
      //   }
      // );

      console.log("email sent");
    } else {
      console.log("Invalid file type or size");
    }
  };

  useEffect(() => {
    formRef?.current
      ?.querySelectorAll(".input-group input")
      .forEach((element) => {
        element.addEventListener("focusin", (e) => {
          const target = e.currentTarget;

          target.closest(".input-group").classList.add("active");
        });

        element.addEventListener("focusout", (e) => {
          const target = e.currentTarget;

          if (!target.value) {
            target.closest(".input-group").classList.add("active");
            console.log("if");
          }
          if (target.value.length === 0) {
            target.closest(".input-group").classList.remove("active");
            console.log("else");
          }
        });
      });

    // console.log(formRef.current);
    // console.log({ firstname, email, jobPosition, fileContent });
  });

  const onFileAttachChange = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    if (file && allowedFileTypes.includes(file.type)) {
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setFileContent(e.target.result);
      };
    } else {
      console.log("error on file selecting");
    }
  };
  return (
    <>
      <Modal onClose={props.onClose}>
        <div className="contactUs-form ">
          <form
            id="testForm"
            // action="/action_page.php"
            ref={formRef}
            onSubmit={(e) => sendEmail(e)}
            enctype="multipart/form-data"
            method="post"
          >
            <span onClick={props.onClose}>
              <GrClose />
            </span>
            <h2>Join Us</h2>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  name="name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                />
                <label className="name" htmlFor="fname">
                  First Name
                </label>
              </div>

              <div className="input-group">
                <input
                  required
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="email" htmlFor="fname">
                  Email
                </label>
              </div>

              <div className="input-group">
                <input
                  type="text"
                  name="openJob"
                  value={jobPosition}
                  required
                  onChange={(e) => setPosition(e.target.value)}
                />
                <label className="job-position" htmlFor="fname"></label>
              </div>
              <div className="btn-container">
                <div className="button-wrap">
                  <input
                    id="upload"
                    accept=".pdf, .txt"
                    type="file"
                    name="attachment"
                    onChange={(e) => onFileAttachChange(e)}
                  ></input>
                </div>
              </div>
              <div>
                {/* <SubmitButton /> */}
                <button type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default JobApply;
