export const dataCareerStage = [
  {
    id: 1,
    image1: "./Images/Career/img1-first-slider.png",
    image2: "./Images/Career/img2-first-slider.png",
    image3: "./Images/Career/img3-first-slider.jpg",
    tagline: "Your partner for",
    headline: "Innovative Software Solutions and Career Growth",
    paragraph:
      "With a passion for innovation, we go beyond conventional boundaries to craft software solutions that redefine possibilities. From groundbreaking applications to complex systems, we harness the latest technologies to build products that make a real impact in today's fast-paced digital world",
    image: "",
  },

  {
    id: 2,
    image1: "./Images/Career/img1-second-slider.png",
    image2: "./Images/Career/img2-second-slider.jpg",
    image3: "./Images/Career/img3-second-slider.png",
    tagline: "Your partner for",
    headline: "Building Excellence Together",
    paragraph:
      "We are more than just a software development company; we are your dedicated partner in achieving software development success. Our unwavering commitment to excellence and collaboration ensures that together, we build extraordinary solutions",
    image: "",
  },

  {
    id: 3,
    image1: "./Images/Career/img1-third-slider.png",
    image2: "./Images/Career/img2-third-slider.png",
    image3: "./Images/Career/img3-third-slider.png",
    tagline: "Your partner for",
    headline: "Empowering Your Vision",
    paragraph:
      "Your vision is the driving force behind everything we do. Our team is passionate about understanding your goals and aspirations. Together, we embark on a collaborative journey to shape the perfect custom software solution.",
    image: "",
  },
];
