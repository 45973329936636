import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { dataCareerStage } from "./dataCareerStage";
import Slider from "react-slick";
import BgImage from "../../Assets/images/bg-career.svg";
import "./dataCareerStage";
import "./CareerStage.scss";

function CareerStage() {
  const [isPaused, setIsPaused] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const slider = document.querySelector(".career-stage");
    if (slider) {
      slider.addEventListener("mouseenter", () => {
        setIsPaused(true);
      });
      slider.addEventListener("mouseleave", () => {
        setIsPaused(false);
      });
    }
  });

  const handleBeforeChange = (currentSlide, nextSlide) => {
    setCurrentSlide(nextSlide);
    const slides = document.querySelectorAll(".slick-slide");
    const allImg = Array.from(
      slides[currentSlide]?.querySelectorAll(".sliderAnimation")
    );
    let delay = 0;

    for (let i = 0; i < allImg.length; i++) {
      setTimeout(() => {
        allImg[i].style.scale = "0";
      }, delay);
      delay += 200;
    }
  };

  const handleAfterChange = (currentSlide) => {
    const slides = document.querySelectorAll(".slick-slide");
    const allImg = Array.from(
      slides[currentSlide]?.querySelectorAll(".sliderAnimation")
    );

    let delay = 0;
    for (let i = 0; i < allImg.length; i++) {
      setTimeout(() => {
        allImg[i].style.scale = "1";
      }, delay);
      delay += 200;
    }
  };

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    draggable: false,
    pauseOnHover: true,
    lazyLoad: true,
    autoplaySpeed: 8000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div>{i + 1}</div>,
    dotsClass: isPaused ? "slick-dots custom-dots-paused" : "slick-dots",
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false,
        },
      },
    ],
  };

  return (
    <section className="career-stage container block stage relative lg:pb-20">
      <div className="career-stage__image">
        <LazyLoadImage
          className="absolute w-[430px] h-[430px] right-[-156px] top-[-120px] object-contain sm:top-[-245px] md:top-[-195px] md:right-[-200px] md:w-[500px] md:h-[500px] md:rotate-[148deg] lg:rotate-0 lg:top-0 lg:right-0 lg:w-[750px] lg:h-[650px]"
          src={BgImage}
          loading="lazy"
        />
      </div>

      <Slider {...settings} className="career-stage__slider">
        {dataCareerStage.map((contents, index) => {
          return (
            <div className="career-stage__wrapper" key={contents.id}>
              <div
                className={`career-stage__left mx-0-important slide-animation sliderAnimation pt-[35vh] lg:flex sm:pt-[6vh] sm:w-3/6 lg:flex-col lg:justify-center lg:pt-[26vh] lg:col-5`}
                id="animated-element"
              >
                <p className="topline" id="change-button">
                  {contents.tagline}
                </p>
                {index === 0 ? (
                  <h1 className="hdl-2">{contents.headline}</h1>
                ) : (
                  <h2 className="hdl-2">{contents.headline}</h2>
                )}
                <p className="copy-text">{contents.paragraph}</p>
              </div>

              <div className="career-stage__right">
                {index === 0 ? (
                  <div className="career-stage__content w-full test-page=1">
                    <div className="career-stage__first-img absolute top-[60px] right-[55px] max-h-[30vw] max-w-[30vw] h-full w-full sm:max-h-[25vw] sm:max-w-[25vw] md:max-w-[20vw] md:max-h-[20vw] md:top-0 md:right-[70px] lg:right-[280px] lg:top-[120px] lg:max-w-[300px] lg:max-h-[300px]">
                      <img
                        className={`slide-animation rounded-borderImg sliderAnimation object-cover h-full w-full`}
                        alt={`stage-slider-${contents.id}`}
                        src={contents.image1}
                      />
                    </div>
                    <div className="career-stage__second-img hidden h-full w-full absolute lg:block lg:max-h-[220px] lg:max-w-[220px] lg:top-0 lg:left-[78%]">
                      <img
                        className={`slide-animation rounded-borderImg sliderAnimation object-cover h-full w-full`}
                        key={contents.id}
                        alt={`stage-slider-${contents.id}`}
                        src={contents.image2}
                      />
                    </div>

                    <div className="career-stage__third-img max-h-[20vw] max-w-[20vw] h-full w-full top-[160px] right-[20px] absolute  sm:max-h-[15vw] sm:max-w-[15vw] md:right-0 lg:top-[330px] lg:right-[220px] lg:max-h-[145px] lg:max-w-[145px]">
                      <img
                        className={`slide-animation rounded-borderImg sliderAnimation h-full w-full object-cover`}
                        key={contents.id}
                        alt={`stage-slider-${contents.id}`}
                        src={contents.image3}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="career-stage__content w-full">
                    <div className="career-stage__first-img absolute top-[60px] right-[55px] max-h-[30vw] max-w-[30vw] h-full w-full sm:max-h-[25vw] sm:max-w-[25vw] md:max-w-[20vw] md:max-h-[20vw] md:top-0 md:right-[70px] lg:right-[280px] lg:top-[120px] lg:max-w-[300px] lg:max-h-[300px]">
                      <LazyLoadImage
                        className={`slide-animation rounded-borderImg sliderAnimation object-cover h-full w-full`}
                        alt={`stage-slider-${contents.id}`}
                        src={contents.image1}
                        loading="lazy"
                      />
                    </div>
                    <div className="career-stage__second-img hidden h-full w-full absolute lg:block lg:max-h-[220px] lg:max-w-[220px] lg:top-0 lg:left-[78%]">
                      <LazyLoadImage
                        className={`slide-animation rounded-borderImg sliderAnimation object-cover h-full w-full`}
                        key={contents.id}
                        alt={`stage-slider-${contents.id}`}
                        src={contents.image2}
                        loading="lazy"
                      />
                    </div>

                    <div className="career-stage__third-img max-h-[20vw] max-w-[20vw] h-full w-full top-[160px] right-[20px] absolute  sm:max-h-[15vw] sm:max-w-[15vw] md:right-0 lg:top-[330px] lg:right-[220px] lg:max-h-[145px] lg:max-w-[145px]">
                      <LazyLoadImage
                        className={`slide-animation rounded-borderImg sliderAnimation h-full w-full object-cover`}
                        key={contents.id}
                        alt={`stage-slider-${contents.id}`}
                        src={contents.image3}
                        loading="lazy"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
}

export default CareerStage;
