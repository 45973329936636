import React, { useRef } from "react";
import HeaderData from "./headerdata";
import { Link } from "react-router-dom";
function BurgerMenu({ isOpen, toggleOpen }) {
  const bodyRef = useRef(document.body);

  let startX = 0;
  let currentX = 0;

  const handleClick = () => {
    if (bodyRef.current.classList.contains("nav-opened")) {
      bodyRef?.current?.classList.toggle("nav-opened");
      toggleOpen();
    }
  };

  const handleTouchStart = (event) => {
    startX = event.touches[0].clientX;
    currentX = startX;
  };

  const handleTouchMove = (event) => {
    currentX = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const threshold = 50; // Adjust this threshold as needed

    if (currentX - startX > threshold) {
      toggleOpen();
      bodyRef?.current?.classList.toggle("nav-opened");
    }
  };

  return (
    <nav
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={`${
        isOpen ? " left-[30px]" : ""
      }  max-lg:bg-white max-lg:border-t-[1px] max-lg:absolute left-[100%] max-lg:pl-6 transition-left duration-300 top-[70px] max-lg:h-screen max-lg:w-screen z-999 `}
    >
      <ul className="flex flex-col lg:flex-row gap-8 max-lg:gap-10 max-lg:mt-10 max-lg:ml-6">
        {HeaderData.map((links) => {
          return (
            <li
              className="navigation-item group hover:after:w-full w-max cursor-pointer list-none relative "
              key={links.id}
            >
              <Link
                // activeClass="active"
                to={links.link}
                className="text-black font-bold p-[3px] transition-all delay-200 group-hover:text-darkGreen max-lg:text-2xl"
                aria-label="Click the logo"
                onClick={() => handleClick()}
              >
                {links.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default BurgerMenu;
