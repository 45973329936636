const data = [
  {
    id: 1,
    jobtitle: "Senior Full Stack Developer",
    openmessage: "If you love creating great user experiences, we want you! We're looking for a talented Full Stack Developer to join our team. If you're good at both frontend and backend development, know React, NodeJS, and React Native, and enjoy working with people from around the world, this job might be perfect for you.",
    criteria: `
          <li>Use React, NodeS, and React Native to make new user-friendly features.</li>
          <li>Make reusable parts and tools for the front of websites and apps.</li>
          <li>Turn designs into code.</li>
          <li>Make sure global websites and apps work well on different devices and browsers.</li>
          <li>Organize how different parts of websites and apps work together.</li>
          <li>Work on big projects for clients from other countries.</li>`,
      qualifications: `
          <li>Finished a degree in computer science, math, physics, or similar.</li>
          <li>Worked with both frontend (javascript) and backend development
          (C#, or python) for more than 5 years.</li>
          <li>Know JavaScript well, including how to change web pages and use objects.</li>
          <li>Really understand React.js.</li>
          <li>Know about common React.js tools and ways of working.</li>
          <li>Understand RESTful APIs.</li>
          <li>Familiarity with RESTful APIs.</li>
          <li>Familiar with protecting websites and apps using JSON Web Token.</li>
          <li>Know about DevOps.</li>
          <li>Care about making code better and sustainable.</li>
      `,
      benefits:`
      <li>Good pay and benefits.</li>
      <li>Regular chances to learn new things by participating in trainings in Germany</li>
      <li>Work on cool proiects with a global team.</li>
      `
  },
  {
      id: 2,
      jobtitle: "Senior Front End",
      openmessage:"Together with you, we want to take our customers' digital solutions to the next level!",
      criteria: `
          <li>Independent development of the frontend of sophisticated websites and applications using modern web technologies.</li>
          <li>You are responsible for architectural tasks and take over the planning and development.</li>
          <li>You have the opportunity to influence the conception and design of websites with technical background knowledge and optimization suggestions.</li>
          <li>Coordinate business requirements and appropriate technical solutions with the product owner as part of the Scrum team
          Active participation in Scrum meetings (daily stand-up, grooming, planning, retrospective).</li>
          <li>Fun in the constant examination of the latest technologies and methods of frontend software development</li>
      `,
      qualifications: `
          <li>Successfully completed university studies in (business) computer science, (business) mathematics, physics or similar
          2-5 years of professional experience in software development.</li>
          <li>Sound knowledge of the front-end technologies JavaScript, React, HTML5 & CSS 3.</li>
          <li>Experience in the implementation of sophisticated templates in Responsive Design and for mobile devices (Mobile First).</li>
          <li>Independent and self-reliant way of working, as well as good English skills.</li>
          <li>If you are open-minded, communicative and proactive in your approach to your tasks, you are the right person for our team.</li>
      `,
      benefits:`
      <li>We offer you an attractive salary and a competitive overall package.</li>
      <li>Regular and systematic (external and internal) training opportunities.</li>
      <li>Modern work in an innovative and international team.</li>
      <li>Regularly trainings for some weeks in Germany.</li>
      `
    },
    {
      id: 3,
      jobtitle: "Senior .NET Developer",
      openmessage: "The ideal candidate will be familiar with the full software design life cycle. They should have experience in coding, testing and consistently managing applications.",
      criteria: `
          <li>Independent development of digital, web-based solutions for our international customers based on the Microsoft .NET framework.</li>
          <li>You take responsibility of tasks in development, planning and architecture.</li>
          <li>Individual solutions and interface programming to third-party systems.</li>
          <li>Coordination of business reguirements and appropriate technical solutions with the product owner as part of the Scrum team.</li>
          <li>Active participation in Scrum meetings (daily stand-up, grooming, planning, retrospective).</li>
          <li>You enjoy the constant examination of the latest technologies and methods of software development.</li>`,
      qualifications: `
          <li>Successfully completed university studies in (business) computer science, (business) mathematics, physics or similar.</li>
          <li>You have profound knowledge in ASP .NET, C# in the web environment. Alternatively, corresponding knowledge in JAVA and the willingness to learn the aforementioned areas.</li>
          <li>Experience in frontend technologies HTML, CSS, JavaScript and web development are desirable 5+ years of experience working with .NET or relevant experiences</li>
          <li>You are characterized by an independent and self-reliant way of working and you have a good command of English.</li>`,
      benefits:`
      <li>We offer you an attractive salary and a competitive overall package.</li>
      <li>Regular and systematic (external and internal) training opportunities.</li>
      <li>Ability to understand business requirements and translate them into technical requirements.</li>
      <li>Regularly trainings for some weeks in Germany.</li>
      `
    },
];

export default data;
