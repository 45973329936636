const teamworkData = [
    {
      id: 1,
      title:'Easy communication',
      images: "./Images/Nearshore/communication.png",
      description:
        "Tirana's workforce is known for its strong language proficiency, particularly in English, which is widely spoken in the tech industry.",
    },
    {
      id: 2,
      title:'Pool of talented young professionals',
      images: "./Images/Nearshore/community.png",
      description:
        "By tapping into this talent pool, you gain access to a diverse range of expertise, including software development, web development, mobile app development, and more..",
    },
  
    {
      id: 3,
      title:'Short distance',
      images: "./Images/Nearshore/flight.png",
      description:
        "Travel to and from our development centre is easy. There are direct flights from multiple European cities with less than 2h travel time.",
    },
  
    {
      id: 4,
      title:'Scalability and Flexibility',
      images: "./Images/Nearshore/scalability.png",
      
      description:
        "Whether you need to ramp up your team quickly for a time-sensitive project or scale down during quieter periods, Tirana.",
    },
    {
        id: 5,
        title:'Cost effective',
      images: "./Images/Nearshore/reduce-cost.png",
        description:
        "Access a skilled talent pool that offers competitive rates without compromising on quality standards.",
      },
      {
        id: 6,
        title:'Cultural Compatibility',
        images: "./Images/Nearshore/values.png",
        description:
          "Located in the heart of Europe, Tirana shares cultural similarities with Western European countries. This cultural compatibility ensures smooth integration, effective communication and a shared understanding of business practices, values and work ethics.",
      },
  ];
  
  export default teamworkData;
  