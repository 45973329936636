import StageSlider from "../components/StageSliders/StageSlider";
import OurTeam from "../components/OurTeam/OurTeam";
import TechContent from "../components/TechSlider/TechContent";
import Nearshore from "../components/Nearshore/Nearshore";
import AboutUs from "../components/AboutUs/AboutUs";
import OpenPositions from "../components/OpenPositions/OpenPositions";
import { animations } from "../Animations/animations";
import React, { useEffect } from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-DOM";

function Home() {
  const getPosition = () => {};
  // useEffect(()=>{
  // const element = document.querySelectorAll(".reveal");
  // const header = document.querySelector("header")
  // element.forEach(el=>{
  // const headerElement = el.previousSibling;
  // const isNextSibling = headerElement.tagName === "HEADER";
  // headerElement.forEach(data=>{
  //   const isNextSibling = data.tagName === "HEADER";
  //   console.log(isNextSibling)
  // })
  // if(headerElement === header){
  //  console.log("true")
  // }else{
  //   console.log("false")
  // }
  //   const isNextSibling = headerElement.tagName === "HEADER";
  //   if (isNextSibling) {
  //     console.log("Component is the next sibling of a header");
  //   } else {
  //     console.log("Component is not the next sibling of a header");
  //   }
  // })
  // })
  window.addEventListener("scroll", animations);
  return (
    <div className="app-wrapper">
      <StageSlider />
      <AboutUs />
      <TechContent />
      <Nearshore />
      <OurTeam />
      <OpenPositions onApply={getPosition} />
    </div>
  );
}

export default Home;
