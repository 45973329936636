import React from "react";
import ServicesStagedata from "./StageServicesdata";

function StageServices() {
  return (
    <section className="mt-[70px]">
      {ServicesStagedata.map((data, index) => {
        return (
          <div className="stage-services relative" key={index}>
            <img className=" block w-full h-[calc(90vh-80px)]" alt="stage services " src={data.img}></img>
            <div className="container max-auto my-0  text-white large:px-px-5 left-1/2 mt-0 absolute bottom-[10%] -translate-x-1/2 z-99" key={data.id}>
              <h1 className="hdl-2 md:hdl-1 xxsm:text-white uppercase text-3xl ">{data.headliner}</h1>
              <p className="copy-text sm:text-lg text-base opacity-80 max-w-[840px] h-[90px] overflow-y-scroll lg:overflow-visible lg:h-auto">{data.description}</p>
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default StageServices;