import React, { useState, useRef } from "react";
import { ReactComponent as Logo } from "../../Assets/delatech-logo.svg";
import { GrClose } from "react-icons/gr";
import { RxHamburgerMenu } from "react-icons/rx";
import BurgerMenu from "./BurgerMenu";
import { isDesktopDevice } from "../../helpers";
import { Link ,useLocation } from "react-router-dom";

function Header() {
  const bodyRef = useRef(document.body);
  const [open, setOpen] = useState(false);

  const referenceClick = () => {
    if (!isDesktopDevice()) {
      bodyRef.current?.classList.toggle("nav-opened");
      setOpen(!open);
    }
  };

  const toggleOpen = () => {
    setOpen(!open);
  };
  const location = useLocation();
  return (
    <header className="fixed bg-white top-0 shadow-headerBoxShadow w-full z-9999">
      <div className="container flex justify-between items-center">
        <div className="max-w-[200px] m-0">
        <a
        href="/AboutUs"
        aria-label="Read more about home"
        className={`w-full ${
          location.pathname === "/" ? "active-link" : ""
        }`}
      >
            <Logo className="h-[70px] w-32 lg:w-full" />
          </a>
        </div>
        <div className="lg:hidden relative">
          <div
            className={`${open ? "hidden" : "block"}`}
            onClick={referenceClick}
          >
            <RxHamburgerMenu fontSize={25} />
          </div>
          <div
            className={`${open ? "block" : "hidden"}`}
            onClick={referenceClick}
          >
            <GrClose fontSize={25} />
          </div>
        </div>
        <BurgerMenu isOpen={open} toggleOpen={toggleOpen} />
      </div>
    </header>
  );
}

export default Header;
