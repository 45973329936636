import React from "react";
import "./OurTeam.scss";
import Team1 from "../../Assets/images/delatech-team.avif";
import ImageTeam from "../../Assets/images/office.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
function OurTeam() {
  return (
    <section className="container reveal pt-[20px] pb-[60px]">
      <div className="row">
        <div className="col-12 lg:col-4 pt-[20px] ">
          <div>
            <div className="text-base sm:text-lg topline">Our Team</div>
            <h2 className="hdl-3 sm:hdl-2 typing-animation">Fast.Precise.Reliable</h2>
            <p className="copy-text">
              Our team is talented and diverse, with skilled developers and
              testers. We work well together, adapting to new projects and
              completing tasks on time. We believe in lifelong learning to stay
              updated with technology. Our main focus at Delatech is solving
              your problems, creating meaningful stories, and delivering work we
              are proud of.
            </p>
          </div>
        </div>

        <div className="image-container relative flex justify-center items-center pt-[40px] sm:py-5 col-12 lg:col-8  lg:mb-10 lg:mt-7 ">
          <div className="reveal">
            <LazyLoadImage
              src={ImageTeam}
              alt="Delatech work"
              className="rounded-full w-[150px] h-[150px] right-[54px] tablet:w-[250px] tablet:h-[250px] lg:w-[350px] lg:h-[350px] relative object-cover max-w-full"
            />
          </div>
          <div className="ourteam__secondary reveal absolute left-[35%] right-0 top-[90px] tablet:left-[47%] tablet:right-[-15px] tablet:top-[179px]">
            <LazyLoadImage
              src={Team1}
              alt="Delatech office"
              className="w-[150px] h-[150px] tablet:w-[200px] tablet:h-[200px] lg:w-[300px] lg:h-[300px] rounded-full block object-cover max-w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurTeam;
