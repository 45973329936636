const sliderImages = [
    {
      id: 1,
      images: "./Images/DevelopmentLifecycle/planning.png",
      alt: "image",
      title: "Planning",
      description: "We choose among competing opportunities for communication so that we can set overall goals for a web."
    },
    {
      id: 2,
      images: "./Images/DevelopmentLifecycle/designing.png",
      alt: "image",
      title: "Designing",
      description: "We create a website, usually focusing on aesthetic factors like layout, user interface, and other visual elements."
    },
  
    {
      id: 3,
      images: "./Images/DevelopmentLifecycle/defining.png",
      alt: "image",
      title: "Defining",
      description: "We define everything from coding a fully-fledged, powerful website and a content management system to a straightforward static one-page website."
    },
  
    {
      id: 4,
      images: "./Images/DevelopmentLifecycle/building.png",
      alt: "image",
      title: "Building",
      description: "Developers work with programming languages like HTML, CSS, and Javascript to create websites."
    },
    {
      id: 5,
      images: "./Images//DevelopmentLifecycle/testing.png",
      alt: "image",
      title: "Testing",
      description: "Check our website for potential bugs before its made live and is accessible to general public."
    },
    {
      id: 6,
      images: "./Images//DevelopmentLifecycle/deployment.png",
      alt: "image",
      title: "Deployment",
      description: "Make our website accessible to users."
    },
    {
      id: 7,
      images: "./Images//DevelopmentLifecycle/maintenace.png",
      alt: "image",
      title: "Maintenance",
      description: "We keep a website up-to-date and running smoothly as well as performing optimally."
    },
  
  
  ];
  
  export default sliderImages;
  