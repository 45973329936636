const sliderLeft = [
  {
    id: 1,
    images: require("../../Assets/slider/blueEye.webp"),
    alt: "image",
  },
  {
    id: 2,
    images: require("../../Assets/slider/development.webp"),
    alt: "image",
  },
  {
    id: 3,
    images: require("../../Assets/slider/exploringMountainView.webp"),
    alt: "image",
  },

  {
    id: 4,
    images: require("../../Assets/slider/exploringWaterfall.webp"),
    alt: "image",
  },

  {
    id: 5,
    images: require("../../Assets/slider/ourTeamTheth.webp"),
    alt: "image",
  },
  {
    id: 6,
    images: require("../../Assets/slider/ourTeamWaterfall.webp"),
    alt: "image",
  },
  {
    id: 7,
    images: require("../../Assets/slider/image-4.png"),
    alt: "image",
  },

  {
    id: 8,
    images: require("../../Assets/slider/tranditional-image-theth.webp"),
    alt: "image",
  },
  {
    id: 9,
    images: require("../../Assets/slider/details.png"),
    alt: "image",
  },
];

export default sliderLeft;
