import acordionData from "./AcordionData";
import React, { useState } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";


const Acordion = ({ animationBubbles }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleMouseEnter = (index) => {
    animationBubbles(index);
  };

  const handleMouseLeave = () => {
    if (openIndex === null) {
      animationBubbles(null);
    }
  };

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      animationBubbles(index);
      setOpenIndex(index);
    }
  };

  return (
    <div>
      {acordionData.map((data, index) => {
        const isActive = openIndex === index;
        const extensionStyle = {
          maxHeight: isActive ? '1000px' : '0',
          opacity: isActive ? 1 : 0,
          transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
        };

        return (
          <div
            className={`menu h-auto cursor-pointer duration-300 ease-in-out transition-all w-full px-8 pt-3 overflow-hidden border-t-[1px] border-solid border-whyUsCardColor last:border-b-[1px] ${
              isActive ? 'active max-h-[1000px] opacity-100' : ''
            }`}
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={data.images} alt={data.alt} className="w-[50px] mr-8" />
                <p className="hdl-4">{data.title}</p>
              </div>
              <div
                className={`arrow transition-transform duration-300 rotate-[270deg] ${
                  isActive ? '' : ''
                }`}
              >
                <MdOutlineArrowForwardIos size={25} />
              </div>
            </div>
            <div className={`extention py-2 ${isActive ? '' : ''}`}>
              <p>{data.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Acordion;