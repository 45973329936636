const sliderImages = [
  {
    id: 1,
    images: require("../../Assets/slider/office_delatech.png"),
    alt: "image",
  },
  {
    id: 2,
    images: require("../../Assets/slider/out_of_office.png"),
    alt: "image",
  },
  {
    id: 3,
    images: require("../../Assets/slider/image-3.png"),
    alt: "image",
  },
  {
    id: 4,
    images: require("../../Assets/slider/image-6.png"),
    alt: "image",
  },
  {
    id: 5,
    images: require("../../Assets/slider/knowledge-sharing.webp"),
    alt: "image",
  },
  {
    id: 6,
    images: require("../../Assets/slider/image-7.png"),
    alt: "image",
  },
  {
    id: 7,
    images: require("../../Assets/slider/image-8.png"),
    alt: "image",
  },
 
  {
    id: 8,
    images: require("../../Assets/slider/meeting-room.png"),
    alt: "image",
  },

  {
    id: 9,
    images: require("../../Assets/slider/image-4.png"),
    alt: "image",
  },

];

export default sliderImages;
