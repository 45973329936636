import React, { useEffect } from "react";
import biSliderData from "./biSliderData";
import sliderLeftData from "./sliderLeftData";
import "./DualSlider.scss";

const DualSlider = () => {
  const numberOfFirstSlides = biSliderData.length;
  const numberOfSecondSlides = sliderLeftData.length;

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--firstSlideLength",
      numberOfFirstSlides
    );
    document.documentElement.style.setProperty(
      "--secondSlideLength",
      numberOfSecondSlides
    );
  }, [numberOfFirstSlides, numberOfSecondSlides]);

  return (
    <section className="dual-slider reveal">
      <div className="grid gap-2">
        <div className="container">
          <h2 className="mb-10 hdl-2 typing-animation">Share Experience</h2>
          <p className="copy-text mb-10 mx-auto">
            At DELAtech, we believe that our greatest asset is our incredible
            team of talented individuals. Our team members come from diverse
            backgrounds, bringing unique perspectives, skills, and expertise to
            the table. Together, we create a collaborative and inclusive work
            environment where everyone's contributions are valued and
            recognized.{" "}
          </p>
        </div>

        <div className="sliders">
          <div className="slide-tracks slider-top">
            {biSliderData.map((data, index) => (
              <div className="slides" key={index}>
                <img src={data.images} alt="" />
              </div>
            ))}{" "}
          </div>
        </div>
        <div className="sliders">
          <div className="slide-tracks slider-bottom">
            {sliderLeftData.map((data, index) => (
              <div className="slides" key={index}>
                <img src={data.images} alt="" />
              </div>
            ))}{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DualSlider;
