import WhyUsedata from "./WhyUsdata";
const WhyUs = () =>{
  
    return(
        <section className="container reveal">
        <div className="row">
        <div className="sm:py-10 py-6 col-10 mx-auto-important">
        <div className="md:text-center text-left ">
            <p className="hdl-2 ">WHY TO CHOOSE DELAtech</p>
            <p className="copy-text">DELAtech  isn't just a service provider; we're architects of growth, pioneers of innovation, and partners in your journey to success. Ready to embark on a transformative experience?</p>
        </div>
        <div className=" cp-row mt-8">
        {WhyUsedata.map(data =>{
            return(
                <div class="flip-card bg-transparent w-full h-80 col-6 lg:col-3 mt-3 lg:mt-0" key={data.id}>
                <div class="flip-card-inner relative w-full h-full text-center shadow-shadow">
                  <div class="flip-card-front absolute w-full h-full bg-whyUsCardColor">
                    <div className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]">
                    <img src={data.images} alt="Avatar" className="w-[100px] h-[100px] m-auto"/>
                    <p className="hdl-4">{data.hdl}</p> 
                    </div>
                  </div>
                  <div class="flip-card-back absolute w-full h-full bg-whyUsCardColor">
                    <p className="flip-card-desc w-9/12 absolute  top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]">{data.description}</p>
                  </div>
                </div>
              </div>
            )
        })}
        </div>
        </div>
        </div>
        </section>
    );
}
export default WhyUs;