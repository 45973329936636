import ContactUsForm from "./ContactUsForm";
import { RiPhoneFill } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
const ContactUsPage = () => {
  return (
    <section className="container">
        <div className="cp-row">
      <div className="lg:col-6">
      <img className="w-full" src={"./Images/StageSlider/StageE.avif"}/>
        <p className="hdl-1">
          Get in <span className="text-darkGreen">touch</span> with our team
        </p>
        <p className="max-w-[700px]">
          Have questions, ideas, or ready to start a project? Our team is here
          to help. Reach out to us and let's begin the conversation. Your vision
          is our priority.
        </p>
        <div className="py-8 flex flex-wrap gap-3">
          <p className="hdl-3 w-full">Contact information</p>
          <button className="relative  p-4 sm:w-[40%] bg-darkGreen text-white rounded-md transition-background-color duration-300 ease-linear hover:bg-openPositionsCardBorder  mr-4">
           <MdOutlineMailOutline size={25} className="absolute left-3"/> info@dela-tech.com
          </button>
          <button className=" relative  p-4 sm:w-[40%] bg-darkGreen text-white rounded-md transition-background-color duration-300 ease-linear hover:bg-openPositionsCardBorder ">
           <RiPhoneFill size={25} className="absolute left-3"/> +355-69-283-5534
          </button>
        </div>
      </div>
      <div className="lg:col-6">
      <ContactUsForm />
      </div>
      </div>
    </section>
  );
};
export default ContactUsPage;
