const WhyUsdata = [
    {
      id: 1,
      images: "./Images/WhyUs/insight.png",
      hdl: "Strategic Technological Insight",
      description:
        "We don't just write code; we architect strategies that align with your business goals. With us, technology isn't just a tool; it's a strategic advantage.",
    },
    {
      id: 2,
      images: "./Images/WhyUs/end-to-end.png",
      hdl: "End-to-End Expertise",
      description:
        "From ideation and design to development, deployment, and ongoing support, we're your one-stop-shop for comprehensive software solutions.",
    },
  
    {
      id: 3,
      images: "./Images/WhyUs/quality.png",
      hdl: "Uncompromising Quality",
      description:
        "Quality isn't negotiable – it's ingrained in everything we do.When you choose DELAtech , you're choosing software that's robust, secure, and dependable.",
    },
  
    {
      id: 4,
      images: "./Images/WhyUs/success.png",
      hdl: "Your Success, Our Fulfillment",
      description:
        "Your success is our ultimate goal. We measure our achievements by the value we bring to your business.",
    },
  ];
  
  export default WhyUsdata;
  