import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Career from "./pages/Career";
import Layout from "./pages/Layout";
import OurServices from "./components/ourServices/OurServices";
import NoPage from "./pages/NoPage";
import React from "react";
import ScrollToTop from "./ScrollToTop";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";
import GridDebugger from "./components/GridDebugger/grid";
import BackToTop from "./components/BackToTop/BackToTop";
// import CareerStage from "./components/Career/CareerStage";
// import { BrowserRouter as Router, Switch, Route } from "react-router-DOM";

function App() {
  return (
    <div className="app-wrapper overflow-x-hidden">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/AboutUs" element={<Home />} />
            <Route path="/ourServices" element={<OurServices />} />
            <Route path="/career" element={<Career />} />
            <Route path="/ContactUs" element={<ContactUsPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <BackToTop />
      <GridDebugger />
    </div>
  );
}

export default App;
