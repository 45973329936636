import "./TechSlider.scss";
import TechSlider from "./TechSlider";
function TechContent() {
  return (
    <section className="tech-slider reveal lg:mb-96 pt-[60px] pb-[60px] bg-gradient-to-r from-coloraqua via-colorlime to-coloraqua">
      <div className="container">
        <div className="lg:row">
          <div className="lg:col-4">
            <p className="text-base sm:text-lg topline text-headlineColor services__label">
              Our Services
            </p>
            <h2 className="hdl-3 sm:hdl-2">
              Delivering with excellence
            </h2>
            <p className="copy-text text-black">
              We pride ourselves on delivering excellence, fastering innovation,
              and building long-lasting partnerships with our clients. Our
              dedication to quality, integrity, and continuous improvement sets
              us apart, and we are ready to embark on a journey of success
              together.
            </p>
          </div>
          <div className="services-slider relative w-full lg:col-8">
            <TechSlider />
          </div>
        </div>
      </div>
    </section>
  );
}
export default TechContent;
