import React from "react";
import { useState } from "react";
import logosData from "./logosData";
import toolsData from "./toolsData";
import "./Tabs.scss";

function Tabs() {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="section-tabs reveal bg-slate-100 mb-0">
      <div className="container">
        <div className="py-14 lg:max-h-full">
          <div className="col-8 mx-auto-important">
            <div className="tabs flex justify-center items-center ">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(1)}
              >
                <p className="hdl-4">Technologies</p>
              </button>
              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(2)}
              >
                <p className="hdl-4">Tools</p>
              </button>
            </div>
            <div className="tabs mt-10">
              <div
                className={
                  toggleState === 1
                    ? "technolgies active-technolgies"
                    : "technolgies"
                }
              >
                <div className="row row-two-columns items-center	">
                  {logosData.map((logo, index) => (
                    <div
                      key={index}
                      className="columns-responsive sm:col-6 md:col-4 xl:col-3 my-10 "
                    >
                      <img
                        src={logo.src}
                        alt={logo.alt}
                        className="w-full h-auto max-w-[100px] my-[0] mx-auto object-cover"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={toggleState === 2 ? "tools active-tools " : "tools"}
              >
                <div className="row row-two-columns items-center">
                  {toolsData.map((tool, index) => (
                    <div
                      key={index}
                      className="columns-responsive sm:col-6 md:col-4 xl:col-3 my-10"
                    >
                      <img
                        src={tool.src}
                        alt={tool.alt}
                        className="w-full h-auto max-w-[130px] my-[0] mx-auto object-cover"
                      />
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-lg-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Tabs;
