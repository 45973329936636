import React, { useState } from "react";
import dataOpenPositions from "./dataopenPosition";
import "./OpenPositions.scss";
import VacantJobImg from "../../Assets/images/team-work.avif";
import { IoIosArrowDown } from "react-icons/io";
import JobApply from "../JobApply/JobApply";
import { LazyLoadImage } from "react-lazy-load-image-component";

function OpenPositions(props) {
  const [isJobApplyOpened, setIsJobApplyOpened] = useState(false),
    [activeJobtitle, setActiveJobTitle] = useState(""),
    [isopen, setisOpen] = useState(false);

  // const openSelectedJob = (title) => {
  //   setIsJobApplyOpened(true);
  //   setActiveJobTitle(title);
  // };

  const closeSelectedJob = () => {
    setIsJobApplyOpened(false);
  };

  const accordionOfJobApply = (event) => {
    const target = event.currentTarget;
    const getParentElement = target.closest(".open-position__block");
    document.querySelectorAll(".open-position__block").forEach((element) => {
      if (!getParentElement.classList.contains("opened")) {
        element.classList.remove("opened");
      }
    });

    // setTimeout(() => {
    getParentElement.classList.toggle("opened");
    setisOpen(!isopen);
    if (getParentElement.classList.contains("opened")) {
    }
    console.log(isopen);
    // }, );
  };

  return (
    <section className="career  container reveal" id="career">
      <div className="open-position-homepage">
        <div className="text-base sm:text-lg topline">
          {" "}
          Become a valued member of our team
        </div>
        <h2 className="hdl-3 sm:hdl-2 typing-animation">
          Dream. Explore. Gain experience
        </h2>
        <p className="copy-text">
          We do everything we can to make it happen. We brainstorm, we imagine,
          we collaborate as a team, we execute, and indeed, we code and test to
          bring ideas to life.
        </p>
        <div className="position-wrapper cp-row">
          <div className="open-position__image lg:min-h-[400px] col-6">
            <LazyLoadImage
              className="object-cover w-full max-h-97"
              src={VacantJobImg}
              alt="girl and boy working in a table"
            />
          </div>
          <div className="vacant-jobs col-6 display-center p-0">
            {dataOpenPositions.map((links) => {
              return (
                <div
                  className="open-position__block group hover:border-darkGreen  border-b-[1px] border-black relative list-none max-h-20 overflow-hidden duration-75 ease-in-out "
                  key={links.id}
                >
                  <span className="relative cursor-pointer pb-2.5">
                    <h2
                      className="my-3.5 hdl-4"
                      onClick={(event) => accordionOfJobApply(event)}
                    >
                      {links.title}
                      <span className="absolute right-5 top-0 duration-300 ease-in-outs">
                        <IoIosArrowDown className="group-hover:fill-darkGreen" />
                      </span>
                      <p>{links.location}</p>
                    </h2>
                  </span>
                  <div
                    className="open-position__description block pb-[30px] ml-5"
                    key={links.id}
                  >
                    <h3 className="hdl-4">The job and technologies</h3>
                    <ul>
                      <li
                        className=" my-4"
                        dangerouslySetInnerHTML={{ __html: links.criteria }}
                      ></li>
                    </ul>
                    <h3 className="hdl-4">Your profile</h3>
                    <ul
                      className="copy-text list-disc"
                      dangerouslySetInnerHTML={{
                        __html: links.profile,
                      }}
                    ></ul>
                    <h3 className="hdl-4">Your benefits</h3>
                    <ul
                      className="copy-text list-disc"
                      dangerouslySetInnerHTML={{
                        __html: links.benefits,
                      }}
                    ></ul>
                    <button className="open-position__btn btn group hover:bg-darkGreen hover:text-white hover:border-darkGreen border-[1px]">
                      <a
                        href="mailto:info@dela-tech.com"
                        aria-label="apply through email"
                        className="hover:text-white"
                      >
                        Apply
                      </a>
                    </button>
                  </div>
                </div>
              );
            })}
            {isJobApplyOpened && (
              <JobApply onClose={closeSelectedJob} onGetData={activeJobtitle} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default OpenPositions;
