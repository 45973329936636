import React from "react";
import CareerStage from "./../components/Career/CareerStage";
import BenefitsNew from "../components/Benefits/BenefitsNew";
import JobPositionsCards from "../components/JobPositionsCards/JobPositionsCards";
import Tabs from "../components/Tabs/Tabs";
import DualSlider from "../components/BISlider/DualSlider";
import {animations} from "../Animations/animations"

function Career() {
  window.addEventListener("scroll", animations);
  return (
    <div className="career-page">
      <CareerStage />
      <BenefitsNew />
      <DualSlider />
      <JobPositionsCards />
      <Tabs />
    </div>
  );
}

export default Career;
