import { Outlet } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import Header from "../components/Header/Header";

const Layout = () => {
  const [isContactOpened, setIsContactOpened] = useState(false);
  const openModal = () => {
    setIsContactOpened(true);
  };
  const closeModal = () => {
    setIsContactOpened(false);
  };
  return (
    <>
      {isContactOpened && <ContactUs onClose={closeModal} />}
      <h1 style={{ display: "none" }}>
        Delatech(Dela-tech): Innovative Web Solutions and Expert Development
        Team
      </h1>
      <Header />
      <Outlet />
      <Footer onShowModal={openModal} />
    </>
  );
};

export default Layout;
