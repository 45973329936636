const logosData =[
{
    src: require("../../Assets/technologies/vuejs.png"),
    alt: "Vue.js",
},
{
    src: require("../../Assets/technologies/react.png"),
    alt: "Vue.js",
},
{
    src: require("../../Assets//technologies/angular.png"),
    alt: "Angular.js",
  },
  {
    src: require("../../Assets/technologies/js.png"),
    alt: "JavaScript",
  },
  {
    
    src: require("../../Assets/technologies/html.png"),
    alt: "HTML",
  },
  {
    src: require("../../Assets/technologies/css.png"),
    alt: "CSS",
  },
  {
    src: require("../../Assets/technologies/less.png"),
    alt: "LESS",
  },
  {
    src: require("../../Assets/technologies/sass.png"),
    alt: "SASS",
  },
  {
    src: require("../../Assets/technologies/tailwindcss.png"),
    alt: "SASS",
  },
  {
    src: require("../../Assets/technologies/cypress.png"),
    alt: "LESS",
  },
  {
    src: require("../../Assets/technologies/playwright.png"),
    alt: "LESS",
  },

];

export default logosData;