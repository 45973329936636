const FooterData = [
  {
    id: 1,
    title: " Home",
    link: "/AboutUs",
  },
  {
    id: 2,
    title: "OurServices",
    link: "/ourServices",
  },
];

export default FooterData;
