import React, { useState, useRef } from "react";
import TeamworkImage from "../../Assets/images/delatech-work.avif";
import { FaArrowDown } from "react-icons/fa";
import nearshoreData from "./nearshoreData";
import { LazyLoadImage } from "react-lazy-load-image-component";
function Nearshore() {
  const [isShowed, setIsShowed] = useState(false);
  const el = useRef(0);
  const readMore = (e) => {
    e.preventDefault();
    setIsShowed(!isShowed);
    el?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="nearshore theme-section reveal bg-nearshoreGrayBackground py-14" id="nearshore">
      <div className="container">
        <div className="text-base sm:text-lg topline mt-0"> Nearshore development</div>
        <h2 className="hdl-3 sm:hdl-2 typing-animation">
          Reduce your development costs significantly and keep the quality
        </h2>
        <div className=" flex justify-normal items-center flex-col md:flex-row">
          <div>
            <LazyLoadImage src={TeamworkImage} alt="nearshore-main-image" className="object-cover lg:max-h-[600px] lg:h-fit md:w-full h-auto w-full xl:w-[900px] shadow-shadow"  />
          </div>
          <div className="nearshore__card shadow-shadow bg-white p-[16px] text-left box-border min-h-[350px] mt-[15px] md:ml-0 md:max-w-[370px] md:relative md:right-8 w-full">
            <h3 className=" hdl-4 sm:hdl-3 nearshore__cardtitle text-left  my-[28px] ">
              The development center in Tirana
            </h3>
            <div className="nearshore__cardescription">
              <p className="copy-text">
                Unleash the Potential of a Nearshore Development Team in Tirana:
                The Perfect Fit for Western European Businesses. Discover why a
                nearshore team in Tirana can be your secret weapon for success.
              </p>
            </div>
            <a
              href="/nearshore"
              aria-label="Read more about our team"
              className={`nearshore__cardbutton flex justify-center items-center w-10 h-10 rounded-full bg-transparent text-white relative overflow-hidden cursor-pointer
                transition-all duration-300 border border-darkGreen hover:bg-darkGreen border-solid group ${
                isShowed ? "show-less transition-all rotate-180 duration-300 ease-linear" : "show-more"
              }`}  onClick={readMore}
            >
              <FaArrowDown size={20} color="#fff" className="fill-darkGreen group-hover:fill-white" />
            </a>
          </div>
        </div>
        <div
          className={`max-h-0 overflow-hidden transition-max-height duration-300 ease-out ${isShowed ? "show-info max-h-[3000px] h-full overflow-hidden transition-max-height duration-[300ms]" : ""}`}
          ref={el}
        >
          <div>
            <p className="text-base sm:text-lg topline  mt-3.5 mb-3.5 text-darkGreen">
              Why nearshore software development
            </p>
            <h2 className= "m-0 hdl-4 sm:hdl-3">
              Nearshore in Tirana is an efficient, easy way to get you reach
              your business targets
            </h2>
            <p className="italic my-4">
              The availability of highly trained specialists makes scaling easy
              and shortens time-to-market on cost-effective and flexible terms.
            </p>
            <p className=" copy-text">
              With a dedicated team, you maintain full control over your
              development and competencies in-house. The distance to your team
              is short and the cultural and linguistic barriers are very small.
            </p>
          </div>

          <div className="xsm:cp-row">
            {nearshoreData.map((data) => {
              return (
                <div className="benefits lg:col-4 sm:col-6 max-[1024px]:col-6 xxsm:col-12" key={data.id}>
                  <div className="benefits__icons text-center">
                    <LazyLoadImage
                      src={data.images}
                      alt={`nearshore-image-${data.id}`}
                      className="w-full max-w-[50px] m-auto"
                    />
                  </div>
                  <h3 className="hdl-4 text-darkGreen text-center">{data.title}</h3>
                  <div className="text-left">
                    <p className="copy-text">{data.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Nearshore;
