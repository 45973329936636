import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./StageSlider.scss";
import stageSliderData from "./stageSliderData";
import { LazyLoadImage } from "react-lazy-load-image-component";
const StageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    fade: true,
    lazyLoad: true,
    arrows: false,
    // autoplaySpeed: 4000,
    // autoplay: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="stage-slider p-0 mt-16 h-[calc(90vh-80px)]">
      <Slider {...settings}>
        {stageSliderData.map((data, index) => {
          // const isLoadingNeeded = index == 0 ? true : false;
          return (
            <div className="hero-image relative" key={data.id}>
              <picture className=" block h-[calc(90vh-80px)]">
                <source
                  media="(max-width: 630px)"
                  srcSet={`${data.mobileImage} 630w`}
                  sizes="630px"
                />
                <source
                  media="(max-width: 1024px)"
                  srcSet={`${data.tabletImage} 1024w`}
                  sizes="1024px"
                />
                <source
                  media="(max-width: 1366px)"
                  srcSet={`${data.largeImage} 1366w`}
                  sizes="1366px"
                />
                {index === 0 ? (
                  <img
                    className="w-full h-full max-w-none object-center object-cover"
                    alt={`stage-slider-${data.id}`}
                    src={data.desktopImage}
                  />
                ) : (
                  <LazyLoadImage
                    className="w-full h-full max-w-none object-center object-cover"
                    alt={`stage-slider-${data.id}`}
                    src={data.desktopImage}
                    loading="lazy"
                  />
                )}
              </picture>
              <div className="container large:px-px-5 flex left-1/2 mt-0 absolute bottom-[70px] -translate-x-1/2 z-9999">
              
              <div
                className="stage-slider__text hidden bg-backgroundPodiumStage shadow-textStageBoxShadow rounded-md box-border lg:w-[400px] relative bottom-0 text-white text-center overflow-hidden p-7 backdrop-blur-xl "
                key={data.id}
              >
                <h2 className="hdl-stage ">{data.title}</h2>
                <p className="copy-text text-left">{data.subtitle}</p>
              </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};
export default StageSlider;
