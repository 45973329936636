const ContactUsForm = () =>{
return(
    <div className="bg-white rounded-lg p-10 shadow-shadow lg:mt-[80px] ">
    <p className="hdl-3 text-darkGreen">Contact us:</p>
    <form className="flex flex-wrap gap-[30px]">
      <div className="flex flex-col w-full sm:w-[45%]">
         <label for="fname" className="font-bold">Name:</label>
          <input type="text" id="fname" name="fname" className="border-[1px] border-slate-300 rounded-lg p-2"/>
      </div>
      <div className="flex flex-col w-full sm:w-[45%]">
         <label for="fname" className="font-bold">Email:</label>
          <input type="text" id="fname" name="fname" className="border-[1px] border-slate-300 rounded-lg p-2"/>
      </div>
      <div className="flex flex-col w-full sm:w-[45%]">
         <label for="fname" className="font-bold">Phone:</label>
          <input type="text" id="fname" name="fname" className="border-[1px] border-slate-300 rounded-lg p-2"/>
      </div>
      <div className="flex flex-col w-full sm:w-[45%]">
         <label for="fname" className="font-bold">Service Interested:</label>
          <select className="border-[1px] border-slate-300 rounded-lg p-2
          ">
            <option>Full Stack Developer</option>
            <option>FE Developer</option>
            <option>BE Developer</option>
            <option>QA</option>
            </select>
      </div>
      <div className="flex flex-col w-full">
         <label for="fname" className="font-bold">Leave us a message:</label>
          <textarea type="text" id="fname" name="fname" className="border-[1px] border-slate-300 rounded-lg p-2" rows="4" />
      </div>

      <button className="p-3 bg-darkGreen text-white rounded-md transition-background-color duration-300 ease-linear hover:bg-openPositionsCardBorder ">Send Message</button>
    </form>
    </div>
);
}
export default ContactUsForm;