const benefitsNewdata = [
       {
      id: 1,
      title:'Professional Development Opportunities',
      images: "./Images/Benefits/development.png",
      description:
        "Offering training, workshops, and opportunities for skill enhancement can help developers stay up-to-date with the latest technologies and trends.",
    },
    {
      id: 2,
      title:'Company Culture',
      images: "./Images/Benefits/company-culture.jpeg",
      description:
        "Incorporating events into the work culture promotes a positive company culture.",
    },
  
    {
      id: 3,
      title:'Peer Learning Sessions',
      images: "./Images/Benefits/knowledge-sharing.png",
      
      description:
        "Encouraging employees to share their knowledge and skills with their colleagues through workshops.",
    },
    {
      id: 5,
      title:'Cultural Exchange',
      images: "./Images/Benefits/germany.png",
      
      description:
        "Through trips to our German office, you'll gain exposure to diverse perspectives, collaborate with top-tier professionals, and learn from the forefront of technology. it's an investment in your development, allowing you to build a career that reaches beyond borders and redefines the boundaries of what's possible in software.",
    },
  ];
  
  export default benefitsNewdata;
  