import React from "react";

function AboutUs() {
  return (
    <section className="container reveal">
      <div className="flex justify-center">
        <div className=" lg:col-10 ">
          <div className="text-base sm:text-lg topline"> About us</div>
          <h2 className="hdl-3 sm:hdl-2 ">
            Delatech: a reliable technical partner for our clients in Europe
            since 2014
          </h2>
          <p className="copy-text">
            Delatech is a skilled and tight-knit software development team based
            in Tirana, Albania. Since 2014, we have been delivering customized
            software to clients in Germany and Italy, with a particular focus on
            web development. Our company stands out due to our exceptional track
            record of delivering projects on time, a team comprised mostly of
            senior software engineers and vast experience providing consulting
            services across diverse business sectors. Our client base has
            expanded rapidly since our inception, and we are proud to have
            developed software for prominent retail and other top brands in
            Western Europe.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
