import "./Modal.scss";
import { GrClose } from "react-icons/gr";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
const Backdrop = (props) => {
  return (
    <div
      className="backdrop left-0 h-full fixed top-0 w-full z-9999 bg-backdrop"
      onClick={props.onClose}
    ></div>
  );
};

const ModalOverlay = (props) => {
  const backgroundClass =
    props.variant === "white" ? "bg-white" : "bg-footerColor";
  const textColorClass = props.color === "white" ? "text-white" : "";

  return (
    <div
      className={`${backgroundClass} modal fixed left-1/2 top-1/2 translate-x-1/2 translate-y-1/2 p-5 z-9999 w-full sm:max-w-[800px] large:max-w-[900px] wide:max-w-[1000px]`}
    >
      <div className="modal-head flex justify-between items-center p-5 w-full">
        <h2 className={`${textColorClass} modal-headline hdl-2 m-0 w-4/5`}>
          {props.headline}
        </h2>
        <button
          className="modal-button bg-white mr-0 border-[1px] border-solid border-neutral-300 rounded-full group hover:bg-darkGreen transition-background-color duration-300 ease-linear w-11 h-11 sm:w-12 sm:h-12 flex justify-center items-center"
          onClick={props.onClose}
        >
          <GrClose className="text-xl" />
        </button>
      </div>
      <div className="content overflow-y-auto max-h-[90vh] sm:max-h-[70vh] h-full px-5">
        {props.children}
      </div>
    </div>
  );
};
const portalElement = document.getElementById("overlays");
const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          headline={props.headline}
          variant={props.variant}
          color={props.color}
          onClose={props.onClose}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};
export default Modal;
