
const NoPage = () => {
    return (
    <section className="container">
      <h2 className="text-red-700 font-bold">404</h2>
      <p>This page is under construction! Check again later.</p>
    </section>
    )
  };
  
  export default NoPage;