import jobPositionData from "./jobPositiondata";
import React, { useState, useEffect } from "react";
import OpenPositionsApply from "../OpenPositionsApply/OpenPositionsApply";
import { BsArrowRight } from "react-icons/bs";
const JobPositionsCards = (props) => {
  const [isJobPositionsOpened, setIsJobPositionsOpened] = useState(false);
  const [activeJobTitle, setActiveJobTitle] = useState("");
  const openModal = (title) => {
    setIsJobPositionsOpened(true);
    setActiveJobTitle(title);
  };
  const closeModal = () => {
    setIsJobPositionsOpened(false);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (isJobPositionsOpened) {
      body.classList.add("active-modal-apply");
    } else {
      body.classList.remove("active-modal-apply");
    }
  });

  return (
    <section className="container reveal job-apply">
      <div className="relative">
        <h2 className="hdl-3 typing-animation sm:hdl-2 w-full">Job Position</h2>
        <div className="overflow-y-hidden w-full">
          <ul
            className={` h-[680px] sm:h-[445px] overflow-y-auto"} transparent-scrollbar overflow-x-hidden pr-5 open-positionCareer scrollbar-track-gray-100 scrollbar-thumb-gray-500 scrollbar-thin scrollbar-thumb-rounded-full`}
          >
            {jobPositionData.map((data, index) => {
              return (
                <li
                  className={`flex w-full flex-col sm:flex-row gap-y-3.5 items-start justify-between mb-5 ${
                    jobPositionData.length > 3 ? "last:mb-10" : ""
                  } shadow-shadow p-5 sm:p-4 sm:items-center border-2 border-solid border-slate-100 group cursor-pointer max-sm:last:mb-14`}
                  onClick={() => openModal(data.jobtitle)}
                  key={data.id}
                >
                  <div className="col-6 flex items-center">
                    <div
                      className="hidden sm:block absolute ml-3 opacity-0 sm:group-hover:opacity-100 transition-all duration-700 ease-linear"
                      onClick={() => openModal(data.jobtitle)}
                    >
                      <BsArrowRight className="text-3xl " />
                    </div>
                    <div className="bg-white sm:group-hover:translate-x-14 transition-all duration-300 ease-linear z-10">
                      <h3 className="hdl-4 sm:hdl-3 font-bold">
                        {data.jobtitle}
                      </h3>
                      <p className="text-darkGreen">
                        {data.jobsmallDescription}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <p className="font-bold my-3">Tirana/Albania</p>
                    <p>Full-time</p>
                  </div>
                  <div className="col-3 lg:col-2 w-full">
                    <button
                      className="w-full flex justify-center px-5 py-2.5 h-11 bg-darkGreen text-white rounded-md transition-background-color duration-300 ease-linear hover:bg-openPositionsCardBorder "
                      onClick={() => openModal(data.jobtitle)}
                    >
                      <span className="mr-2">➜</span>Apply
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          {isJobPositionsOpened && (
            <OpenPositionsApply
              onClose={closeModal}
              onGetData={activeJobTitle}
            />
          )}
        </div>
      </div>
    </section>
  );
};
export default JobPositionsCards;
