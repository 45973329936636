import "./ContactUs.scss";
import Modal from "../Modal/Modal";
import { GrClose } from "react-icons/gr";
import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

const ContactUs = (props) => {
  const [emailSend, setEmailSend] = useState(false);
  const [message, setMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
    },
  });
  const formRef = useRef(null);
  useEffect(() => {
    formRef?.current
      ?.querySelectorAll(".input-group .inputs")
      .forEach((element) => {
        element.addEventListener("focusin", (e) => {
          const target = e.currentTarget;
          target.closest(".input-group").classList.add("active");
        });

        element.addEventListener("focusout", (e) => {
          const target = e.currentTarget;
          if (!target.value) {
            target.closest(".input-group").classList.add("active");
          }
          if (target.value.length === 0) {
            target.closest(".input-group").classList.remove("active");
          }
        });
      });
  });

  const sendEmail = (e) => {
    setEmailSend(true);
    emailjs
      .sendForm(
        "service_7ma9a9r",
        "template_lrx9nrn",
        formRef.current,
        "-EHKjnJAv3q0Fd3Ph"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage(
            "Your message Successfully Sent. Thank you for your Application!"
          );

          setTimeout(() => {
            props.onClose();
          }, 2000);
        },
        (error) => {
          setMessage(error.text);
          console.log("error");
        }
      );
  };

  return (
    <>
      <Modal
        variant="dark"
        onClose={props.onClose}
        headline={"Contact Us"}
        color="white"
      >
        <div className="contactUs-form ">
          <form
            action="/action_page.php"
            ref={formRef}
            onSubmit={handleSubmit(sendEmail)}
          >
            <div>
              <div className="input-group">
                <input
                  className="inputs"
                  type="text"
                  {...register("name", {
                    required: true,
                    minLength: { value: 3, message: "Min length is 3" },
                  })}
                  aria-invalid={errors.name ? "true" : "false"}
                />
                <label className="name" htmlFor="fname">
                  Name*
                </label>
                {errors.name?.type === "required" && (
                  <p role="alert">This field is required</p>
                )}
              </div>
              <div className="input-group">
                <input
                  className="inputs"
                  type="email"
                  {...register("email", { required: true })}
                  aria-invalid={errors.email ? "true" : "false"}
                />
                <label className="email" htmlFor="fname">
                  Email*
                </label>
                {errors.email?.type === "required" && (
                  <p role="alert">This field is required</p>
                )}
              </div>
              <div className="input-group">
                <textarea
                  className="inputs"
                  row="6"
                  column="7"
                  type="text"
                  {...register("message", {
                    required: true,
                  })}
                  aria-invalid={errors.message ? "true" : "false"}
                />
                <label className="subject" htmlFor="message">
                  Subject:
                </label>
                {errors.message?.type === "required" && (
                  <p role="alert">This field is required</p>
                )}
              </div>
              <div>
                <div className="submit-button">
                  <button type="submit"> Submit </button>
                </div>
              </div>
              <div className="message-send">
                <div className="message">{message}</div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default ContactUs;
