import React from "react";
import ReactDOM from "react-dom/client";
import "./tailwind.scss";
import "./Animations/TextAnimations/textAnimations.scss";
import "./Animations/Animations.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 