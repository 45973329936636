import React, { useRef } from "react";
import Slider from "react-slick";
import "./TechSlider.scss";
import sliderData from "./sliderData";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TechSlider = () => {
  const sliderRef = useRef(null);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <AiOutlineArrowRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <AiOutlineArrowLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    LazyLoad: "ondemand",
    // autoplay: false,
    speed: 300,
    // autoplaySpeed: 1000,
    loop: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 759,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 619,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
    beforeChange: (prev, next) => {
      const prevSlideElement = sliderRef.current.innerSlider.list.querySelector(
        `[data-index ="${prev}"]`
      );
      const nextSlideElement = sliderRef.current.innerSlider.list.querySelector(
        `[data-index ="${next}"]`
      );
      if (prevSlideElement && nextSlideElement) {
        prevSlideElement.classList.add("slick-active");
      }
    },
  };

  return (
    <>
      <div className="lg:absolute overflow-x-hidden w-full z-9999">
        <Slider {...settings} ref={sliderRef}>
          {sliderData.map((data) => {
            return (
              <div className="h-full p-5 overflow-hidden mx-2" key={data.id}>
                <div className="flex justify-center">
                  <LazyLoadImage
                    className="object-contain w-full h-[80px]"
                    src={data.images}
                    alt={`tech-slider-${data.id}`}
                    effect="opacity"
                  />
                </div>
                <div className="mt-5">
                  <h2 className="hdl-4 sm:hdl-3 my-2.5">
                    {data.hdl}
                  </h2>
                  <p className=" m-0 leading-6">
                    {data.description}
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default TechSlider;
